import { Bitcoin, Fan, Hammer, Zap } from "lucide-react";
import { useState, useMemo, useEffect } from "react";
import {
  Line,
  LineChart,
  AreaChart,
  Area,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  ReferenceLine,
} from "recharts";
import Logo from "@/logo.svg";
import axiosClient from "@/libs/axiosClient";

interface PoolStats {
  hash_rate_charts: {
    "24HOUR": Record<string, number>;
  };
  now: string;
  worker_stats: {
    total_count: number;
    status: {
      online: number;
      offline: number;
      dead: number;
    };
  };
  btc_price: number;
  network_hashrate: number;
}

const timeRanges = {
  "3h": { hours: 3, interval: 5 },
  "6h": { hours: 6, interval: 15 },
  "12h": { hours: 12, interval: 30 },
  "1d": { hours: 24, interval: 60 },
} as const;

const convertToEH = (hashrate: number, fromUnit: "H" | "MH") => {
  const conversions = {
    H: hashrate / 1000 ** 6, // H to EH
    MH: hashrate / 1000 ** 4, // MH to EH
  };
  return conversions[fromUnit];
};

const getYAxisDomain = (data: { hashrate: number }[]) => {
  if (data.length === 0) {
    return { min: 0, max: 1, ticks: [0, 0.2, 0.4, 0.6, 0.8, 1] };
  }

  const minHashrate = 0; // Force minimum to 0
  const maxHashrate = Math.max(...data.map((d) => d.hashrate));
  const padding = maxHashrate * 0.1;

  const max = Math.ceil((maxHashrate + padding) * 10) / 10;
  const step = Math.ceil((max / 10) * 10) / 10;

  return {
    min: 0,
    max,
    ticks: Array.from({ length: 11 }, (_, i) => Math.round(i * step * 10) / 10),
  };
};

export default function StatsPage() {
  const [selectedRange, setSelectedRange] =
    useState<keyof typeof timeRanges>("12h");
  const [poolStats, setPoolStats] = useState<PoolStats | null>(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const { data } = await axiosClient.get<PoolStats>("/api/v1/reports/pool_stats/");
        setPoolStats(data);
      } catch (error) {
        console.error("Failed to fetch pool stats:", error);
      }
    };

    fetchStats();
    const interval = setInterval(fetchStats, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, []);

  const formatXAxis = (timestamp: number) => {
    const date = new Date(timestamp);
    // Convert to GMT+5
    const gmt5Time = new Date(date.getTime() + (5 * 60 * 60 * 1000));
    return `${String(gmt5Time.getUTCHours()).padStart(2, "0")}:${String(
      gmt5Time.getUTCMinutes()
    ).padStart(2, "0")}`;
  };

  const formatHashrate = (value: number) => value.toFixed(2);

  const chartData = useMemo(() => {
    if (!poolStats) return [];

    return Object.entries(poolStats.hash_rate_charts["24HOUR"])
      .map(([timestamp, hashrate]) => ({
        timestamp: parseInt(timestamp) * 1000,
        hashrate: convertToEH(hashrate, "MH"),
      }))
      .sort((a, b) => a.timestamp - b.timestamp);
  }, [poolStats]);

  const latestHashrate = useMemo(() => {
    if (chartData.length === 0) return 0;
    return chartData[chartData.length - 1].hashrate;
  }, [chartData]);

  const filteredData = useMemo(() => {
    if (chartData.length === 0) return [];

    const range = timeRanges[selectedRange];
    const windowMs = range.hours * 60 * 60 * 1000;
    const latestTimestamp = Math.max(
      ...chartData.map((point) => point.timestamp)
    );
    const windowStart = latestTimestamp - windowMs;

    return chartData.filter((point) => point.timestamp >= windowStart);
  }, [selectedRange, chartData]);

  const dailyData = useMemo(() => {
    if (chartData.length === 0) return [];

    const range = timeRanges["1d"];
    const windowMs = range.hours * 60 * 60 * 1000;
    const latestTimestamp = Math.max(
      ...chartData.map((point) => point.timestamp)
    );
    const windowStart = latestTimestamp - windowMs;

    return chartData.filter((point) => point.timestamp >= windowStart);
  }, [chartData]);

  const xAxisConfig = {
    dataKey: "timestamp",
    stroke: "#6B7280",
    tick: {
      fill: "#9CA3AF",
      fontSize: 11,
      dy: 10,
    },
    tickFormatter: formatXAxis,
    padding: { left: 0, right: 0 },
    minTickGap: 50,
    angle: -45,
    textAnchor: "end" as const,
    height: 60,
  };

  const { min: yMin, max: yMax, ticks } = getYAxisDomain(filteredData);
  const maxHashrate = Math.max(...filteredData.map((d) => d.hashrate));

  if (!poolStats) return <div>Loading...</div>;

  return (
    <div className="min-h-screen bg-slate-950 text-white py-12 px-6">
      <div className="max-w-[96rem] mx-auto">
        <div className="mb-8">
          <img src={require("@/images/main/main-logo.png")} alt={"main-icon"} className="h-12" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
          <div className="bg-slate-900 py-4 px-7 rounded-xl">
            <div className="flex justify-between items-start mb-2">
              <span className="text-white font-bold text-xl">
                Bitcoin price
              </span>
              <div className="w-16 h-16 rounded-full bg-yellow-500 flex items-center justify-center shadow-[0_0_6px_rgba(234,179,8,0.8)] !border-2 !border-white/30">
                <Bitcoin className="text-white" size={32} />
              </div>
            </div>
            <div className="text-4xl font-extrabold">
              ${poolStats.btc_price.toLocaleString()}
            </div>
          </div>

          <div className="bg-slate-900 py-4 px-7 rounded-xl">
            <div className="flex justify-between items-start mb-2">
              <span className="text-white font-bold text-xl">
                Pool hashrate
              </span>
              <div className="w-16 h-16 rounded-full bg-blue-600 flex items-center justify-center shadow-[0_0_6px_rgba(29,78,216,0.8)] !border-2 !border-white/15">
                <Fan className="text-white" size={32} />
              </div>
            </div>
            <div className="text-4xl font-extrabold">
              {formatHashrate(latestHashrate)} EH/s
            </div>
          </div>

          <div className="bg-slate-900 py-4 px-7 rounded-xl">
            <div className="flex justify-between items-start mb-2">
              <span className="text-white font-bold text-xl">
                Network hashrate
              </span>
              <div className="w-16 h-16 rounded-full bg-blue-600 flex items-center justify-center shadow-[0_0_6px_rgba(29,78,216,0.8)] !border-2 !border-white/15">
                <Zap className="text-white" size={32} />
              </div>
            </div>
            <div className="text-4xl font-extrabold">
              {formatHashrate(convertToEH(poolStats.network_hashrate, "H"))}{" "}
              EH/s
            </div>
          </div>

          <div className="bg-slate-900 py-4 px-7 rounded-xl">
            <div className="flex justify-between items-start mb-2">
              <span className="text-white font-bold text-xl">Workers</span>
              <div className="w-16 h-16 rounded-full bg-blue-600 flex items-center justify-center shadow-[0_0_6px_rgba(29,78,216,0.8)] !border-2 !border-white/15">
                <Hammer className="text-white" size={32} />
              </div>
            </div>
            <div className="text-4xl font-extrabold">
              {poolStats.worker_stats.total_count.toLocaleString()}
            </div>
          </div>
        </div>

        <div className="bg-slate-900 py-6 px-12 rounded-2xl flex-1 h-full">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-3xl font-bold text-white pr-6">
              Pool hashrate
            </h2>
            <div className="flex gap-2">
              {Object.keys(timeRanges).map((range) => (
                <button
                  key={range}
                  onClick={() =>
                    setSelectedRange(range as keyof typeof timeRanges)
                  }
                  className={`px-3 py-2 rounded-lg font-semibold ${
                    selectedRange === range
                      ? "bg-blue-600 text-white shadow-[0_0_6px_rgba(29,78,216,0.8)] !border-2 !border-white/15"
                      : "bg-blue-600/20 text-white hover:bg-blue-600/30"
                  }`}
                >
                  {range}
                </button>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-8">
            <div className="h-[500px]">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart
                  data={filteredData}
                  margin={{ top: 10, right: 0, left: 0, bottom: 40 }}
                >
                  <XAxis {...xAxisConfig} />
                  <YAxis
                    stroke="#6B7280"
                    tick={{ fill: "#9CA3AF" }}
                    domain={[0, yMax]}
                    ticks={ticks}
                    tickMargin={10}
                    tickFormatter={(value) => value.toFixed(1)}
                  />
                  <ReferenceLine y={maxHashrate} stroke="#374151" />
                  <Tooltip
                    contentStyle={{
                      backgroundColor: "#1E1E3F",
                      border: "1px solid #2A2A4F",
                      borderRadius: "0.375rem",
                    }}
                    labelStyle={{ color: "#E5E7EB" }}
                    labelFormatter={(timestamp) => formatXAxis(timestamp)}
                    formatter={(value: number) => [
                      formatHashrate(value),
                      "Hashrate",
                    ]}
                  />
                  <CartesianGrid
                    vertical={true}
                    horizontal={false}
                    stroke="#374151"
                  />
                  <Line
                    type="monotone"
                    dataKey="hashrate"
                    stroke="#6366F1"
                    strokeWidth={3}
                    dot={false}
                    name="Hashrate"
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>

            <div>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={dailyData}
                    margin={{ top: 10, right: 0, left: 0, bottom: 40 }}
                  >
                    <XAxis {...xAxisConfig} />
                    <YAxis
                      stroke="#6B7280"
                      tick={{ fill: "#9CA3AF" }}
                      domain={[0, yMax]}
                      ticks={ticks}
                      tickMargin={10}
                      tickFormatter={(value) => value.toFixed(1)}
                    />
                    <ReferenceLine y={maxHashrate} stroke="#374151" />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: "#1E1E3F",
                        border: "1px solid #2A2A4F",
                        borderRadius: "0.375rem",
                      }}
                      labelStyle={{ color: "#E5E7EB" }}
                      labelFormatter={(timestamp) => formatXAxis(timestamp)}
                      formatter={(value: number) => [
                        formatHashrate(value),
                        "Hashrate",
                      ]}
                    />
                    <CartesianGrid
                      vertical={true}
                      horizontal={false}
                      stroke="#374151"
                    />
                    <Area
                      type="monotone"
                      dataKey="hashrate"
                      stroke="#6366F1"
                      fill="#6366F1"
                      fillOpacity={0.2}
                      strokeWidth={3}
                      dot={false}
                      name="Hashrate"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>

              <div className="flex items-center justify-center mt-4">
                <div className="flex items-center gap-2">
                  <div className="w-3 h-3 rounded-full bg-indigo-500"></div>
                  <span className="text-gray-300">Hashrate</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
