import {
  Card,
  Spinner,
  Table,
  Alert as BootAlert,
  Button,
} from "react-bootstrap";
import style from "./index.module.scss";
import { RootState, useAppDispatch } from "@/store";
import { useSelector } from "react-redux";
import { useSubAccount } from "@/hooks";
import { useEffect, useState } from "react";
import {
  createApiKey$,
  deleteApiKey$,
  generateQrCode,
  getAllApiKeys$,
  getNotifications,
  getTrustedEmails,
  isQrCodeEnabled,
  qrCodeEnable,
} from "@/store/api_key";
import { Input } from "@/components/Input";
import { Alert } from "@/components/Alert";
import { useTranslations } from "use-intl";
import { CabinetCard } from "@/components/Card";
import { useSearchParams } from "react-router-dom";
import { CabinetMobileSettings } from "@/features";
import { CustomTable } from "@/components/Table/CustomTable";
import { LANGS } from "@/shared/ui/language-component";
import { setLang } from "@/store/auth";
import classNames from "classnames";
enum EType {
  FA = "2fa",
  API = "api",
  EMAILS = "emails",
  NOTIFICATIONS = "notification",
  LANGUAGE = "language",
}
export default function Settings() {
  const dispatch = useAppDispatch();
  const apiKeys = useSelector((state: RootState) => state.apiKey.apiKeys);
  const { currentSubAccount } = useSubAccount();
  const loading = useSelector((state: RootState) => state.apiKey.loading);
  const [notification, setNotifications] = useState(null);
  const [faCode, setFaCode] = useState("");
  const lang = useSelector((state: RootState) => state.auth.lang);
  const [alert, setAlert] = useState<any>(null);
  const [qr, setQr] = useState<string>("");
  const [params, _] = useSearchParams();
  const [loadingQr, setLoading] = useState(true);
  const [trustedEmails, setTrustedEmails] = useState<any>(null);
  useEffect(() => {
    let promise: any;
    if (currentSubAccount) {
      promise = dispatch(getAllApiKeys$(currentSubAccount));
    }
    getTrustedEmails().then((res) => {
      setTrustedEmails(res.data);
    });
    Promise.all([isQrCodeEnabled(), getNotifications()]).then(
      ([{ is_otp_enabled }, notifications]: any) => {
        setNotifications(notifications.token);
        if (!is_otp_enabled) {
          generateQrCode()
            .then((res: any) => {
              setQr(res);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setLoading(false);
        }
      },
    );
    return () => {
      if (promise) {
        promise.abort();
      }
    };
  }, [currentSubAccount]);
  const createApiKey = () => {
    dispatch(createApiKey$(currentSubAccount));
  };
  const deleteKey = (key: string) => {
    dispatch(
      deleteApiKey$({ sub_account_name: currentSubAccount, api_key: key }),
    );
  };
  const i18n = useTranslations("cabinet.settings");
  const i18nMobile = useTranslations("cabinet.mobile");

  const addFaKey = () => {
    qrCodeEnable(faCode)
      .then(() => {
        setAlert({ text: i18n("success2fa"), type: "success" });
      })
      .catch(() => {
        setAlert({
          text: i18n("invalid2fa"),
          type: "error",
        });
      });
  };

  function getComponentByPage(page: EType) {
    switch (page) {
      case EType.LANGUAGE:
        return (
          <div className={style.mainSettings__langs}>
            <ul>
              {LANGS.map((value, key) => (
                <li
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setLang(value.code));
                  }}
                  key={key}
                  className={lang === value.code ? style.active : ""}
                >
                  <a href="#" className="js_changeLang">
                    <span className={value.icon}></span>
                    <span>{value.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        );
      case EType.EMAILS:
        return (
          <CabinetCard className={style.settings}>
            <h5>{i18nMobile("trusted")}</h5>
            {trustedEmails ? (
              <>
                {trustedEmails.length === 0 ? (
                  <span className={style.settings__text}>{i18n("text")}</span>
                ) : (
                  <ul>
                    {trustedEmails.map((res: string) => (
                      <li>{res}</li>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              <Spinner animation={"grow"} />
            )}
          </CabinetCard>
        );
      case EType.NOTIFICATIONS:
        return (
          <CabinetCard className={style.settings}>
            <h5>{i18nMobile("notification")}</h5>
            {notification ? (
              <div className={"pl-3 text-[14px]"}>
                <p className={"text-[14px] text-gray-600"}>
                  {i18nMobile("tokenText")}:{" "}
                  <span
                    className={classNames(
                      style.settings__tel__main,
                      "text-black",
                    )}
                  >
                    <b>{notification}</b>
                  </span>
                </p>
                <p className={"text-[14px] text-gray-600"}>
                  {i18nMobile("linkBot")} <br />
                  <a
                    href="https://t.me/btcpool_manager_bot"
                    target={"_blank"}
                    rel="noreferrer"
                    className={
                      "text-decoration-underline text-blue-500 underline"
                    }
                  >
                    https://t.me/btcpool_manager_bot
                  </a>
                </p>
              </div>
            ) : (
              <Spinner animation={"grow"} />
            )}
          </CabinetCard>
        );
      case EType.API:
        return (
          <CabinetCard className={style.settings}>
            <h5>{i18n("useApi")}.</h5>
            {loading ? (
              <Spinner animation={"grow"} />
            ) : (
              <>
                <Table>
                  <thead>
                    <tr>
                      <th>Api Key </th>
                      <th>{i18n("table.created")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {apiKeys.map((key, idx) => (
                      <tr key={idx}>
                        <td>
                          <i
                            className={"fa fa-trash-alt"}
                            onClick={() => deleteKey(key)}
                          ></i>
                          {key}
                        </td>
                        <td>-</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <button
                  className={"btn btn-primary btn-sm"}
                  onClick={() => createApiKey()}
                >
                  {i18n("showApi")}
                </button>
              </>
            )}
          </CabinetCard>
        );
      case EType.FA:
        return (
          <CabinetCard className={style.settings}>
            {loadingQr ? (
              <Spinner animation={"grow"} />
            ) : (
              <>
                <h5>{i18n("2fa")}</h5>
                {qr ? (
                  <div className={style.settings__qr}>
                    <h5>{i18n("heading")}</h5>
                    <div className={style.settings__qr__content}>
                      <div dangerouslySetInnerHTML={{ __html: qr }}></div>
                      <div>
                        <p style={{ whiteSpace: "pre-line" }}>
                          {i18n("description")}
                        </p>
                        <div className={"d-flex gap-3"}>
                          <Input
                            placeholder={"2FA CODE"}
                            value={faCode}
                            onChange={(e) => setFaCode(e.target.value)}
                          />
                          <div>
                            <button
                              className={"btn btn-primary "}
                              onClick={() => addFaKey()}
                            >
                              {i18n("bind")}
                            </button>
                          </div>
                        </div>
                        {alert && (
                          <BootAlert
                            variant={
                              alert.type === "error" ? "danger" : alert.type
                            }
                            className={style.settings__qr__alert}
                          >
                            {alert.text}
                          </BootAlert>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <BootAlert variant={"success"}>{i18n("enabled")}</BootAlert>
                )}
              </>
            )}
          </CabinetCard>
        );
    }
  }
  return (
    <div className={style.mainSettings}>
      <div className={style.mainSettings__wrapper}>
        <h1>{i18n("generalSettings")}</h1>
        <div className={"flex gap-4 flex-1"}>
          <div className={"w-[40%]"}>
            {getComponentByPage(EType.NOTIFICATIONS)}
            {getComponentByPage(EType.EMAILS)}
            {getComponentByPage(EType.API)}
          </div>
          <div className={"w-50"}>{getComponentByPage(EType.FA)}</div>
        </div>
      </div>
      <div className={style.settings__mobilePage}>
        {params.get("page") ? (
          getComponentByPage(params.get("page") as EType)
        ) : (
          <CabinetMobileSettings />
        )}
      </div>
    </div>
  );
}
