import { RootState, useAppDispatch } from "@/store";
import { useSelector } from "react-redux";
import { useSubAccount } from "@/hooks";
import { useEffect, useState } from "react";
import {
  getAllSubAccounts$,
  setCurrentSubAccount,
  setDialogs,
  setLoading,
} from "@/store/sub_accounts";
import { Spinner } from "react-bootstrap";
import { CabinetCard } from "@/components/Card";
import { CustomTable } from "@/components/Table/CustomTable";
import style from "./index.module.scss";
import { useTranslations } from "use-intl";
import { useNavigate } from "react-router-dom";
import { SubAccountSummaryDto } from "@/models";
import { EditWalletAlert } from "@/features/ui/cabinet-alert-components";
import { EDIT_WALLET } from "@/variables";

const walletAddressText = (wallet: string) =>
  wallet.substring(0, 20) + " " + wallet.substring(20, wallet.length);

export default function SubAccountsPage() {
  const dispatch = useAppDispatch();
  const dialogs = useSelector((state: RootState) => state.subAccount.dialogs);
  const router = useNavigate();
  const [chosenWallet, setChosenWallet] = useState("");
  const [chosenSubAccount, setChosenSubAccount] =
    useState<SubAccountSummaryDto | null>(null);
  const subAccounts = useSelector(
    (state: RootState) => state.subAccount.subAccounts,
  );
  const { currentSubAccount, loading } = useSubAccount();
  useEffect(() => {
    let promise: any;
    dispatch(setLoading(true));
    promise = dispatch(getAllSubAccounts$());
    promise.finally(() => dispatch(setLoading(false)));
    return () => {
      dispatch(setLoading(false));
      if (promise) {
        promise.abort();
      }
    };
  }, []);
  const i18n = useTranslations("cabinet");
  return (
    <>
      {loading ? (
        <Spinner animation={"grow"} />
      ) : (
        <CabinetCard className={"mb-[20px]"}>
          <CustomTable
            notFoundText={i18n("notFoundText")}
            headers={[
              "ID",
              i18n("subaccounts.header.username"),
              i18n("subaccounts.header.commission"),
              i18n("subaccounts.header.wallet"),
              i18n("subaccounts.header.currency"),
              i18n("subaccounts.header.earningScheme"),
            ]}
            columns={subAccounts.map((account) => {
              return {
                data: [
                  "id",
                  "name",
                  "commission",
                  "wallet_address",
                  "crypto_currency",
                  "earning_scheme",
                ].map((key) => {
                  if (key === "wallet_address") {
                    return (
                      <div className={style.subaccounts__wallet}>
                        <div className={style.subaccounts__wallet__text}>
                          {walletAddressText(account[key])}
                        </div>
                        <div
                          className={style.subaccounts__wallet__img}
                          onClick={() => {
                            setChosenWallet(account.wallet_address);
                            if (account.virtual_sub_accounts?.length > 1) {
                              dispatch(setCurrentSubAccount(account.name));
                              router("/cabinet/settings?page=personal");
                            } else {
                              setChosenSubAccount(account);
                              dispatch(
                                setDialogs({
                                  key: EDIT_WALLET,
                                  value: true,
                                }),
                              );
                            }
                          }}
                        >
                          <img
                            src={require("@/images/main/edit.svg").default}
                            alt={"edit"}
                          />
                        </div>
                      </div>
                    );
                  }
                  return account[key];
                }),
              };
            })}
          />
        </CabinetCard>
      )}
      {dialogs[EDIT_WALLET] && chosenSubAccount && (
        <EditWalletAlert
          currentWallet={chosenWallet}
          subAccount={chosenSubAccount}
        />
      )}
    </>
  );
}
