import { CButton } from "@/components";
import style from "./index.module.scss";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppDispatch } from "@/store";
import { toast } from "react-toastify";
import { SubAccountSummaryDto } from "@/models";
import { setDialogs, updateSubAccountWallet } from "@/store/sub_accounts";
import { EDIT_WALLET } from "@/variables";
import { CInput } from "@/components/Input/CInput";
import { CustomDialog } from "@/components/Dialog";

const WalletScheme = Yup.object().shape({
  wallet_address: Yup.string().required("Required"),
});

export function EditWalletAlert({
  currentWallet,
  subAccount,
}: {
  currentWallet: string;
  subAccount: SubAccountSummaryDto;
}) {
  const dispatch = useAppDispatch();
  const formik = useFormik({
    initialValues: { wallet_address: "", sub_account_id: "" },
    validationSchema: WalletScheme,
    validateOnChange: false,
    onSubmit: (values, formikHelpers) => {
      formikHelpers.setSubmitting(true);
      dispatch(
        updateSubAccountWallet({
          sub_account_id: subAccount.id || -1,
          wallet_address: values.wallet_address,
        }),
      )
        .unwrap()
        .then(() => {
          toast.success("Instructions sent to email");
          dispatch(
            setDialogs({
              key: EDIT_WALLET,
              value: false,
            }),
          );
        })
        .catch((e: any) => {
          toast.error(e?.message || "Unexpected error");
        })
        .finally(() => {
          formikHelpers.setSubmitting(false);
        });
    },
  });
  return (
    <CustomDialog
      heading={"Edit Wallet Address"}
      show={true}
      onClose={() => dispatch(setDialogs({ key: EDIT_WALLET, value: false }))}
      body={
        <div className={style.editWallet}>
          <form
            className={style.editWallet__form}
            onSubmit={(e) => formik.handleSubmit(e)}
          >
            <div className={style.editWallet__form__prev}>
              <span>Current Wallet Address</span>
              <p>{currentWallet}</p>
            </div>
            <CInput
              error={formik.errors.wallet_address}
              name={"wallet_address"}
              onChange={formik.handleChange}
              label={"New Wallet Address"}
              placeholder={"Wallet Address"}
            />
            <div className={style.editWallet__form__warning}>
              * The added or changed wallet will be verified within 24 hours.
              During this period, auto-payments will not be available
            </div>
            <div className={style.editWallet__form__btn}>
              <CButton
                value={"Edit"}
                disabled={formik.isSubmitting}
                type={"submit"}
                variant={"general"}
              />
            </div>
          </form>
        </div>
      }
    />
  );
}
