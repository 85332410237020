export const GET_ALL_SUB_ACCOUNTS = "subaccount/all";
export const CREATE_SUB_ACCOUNT = "subaccount/create";
export const GET_SUB_ACCOUNT_SUMMARY = "subaccount/summary";
export const GET_SUB_ACCOUNT_PAYOUT = "subaccount/payouts";
export const GET_SUB_ACCOUNT_WORKERS = "subaccount/workers";
export const GET_SUB_ACCOUNT_STRATUM = "subaccount/stratum";
export const GET_SUB_ACCOUNT_EARNINGS = "subaccount/earnings";
export const GET_STATISTICS = "statistics";
export const GET_SUB_ACCOUNT_CHART = "subaccount/charts";
export const UPDATE_SUB_ACCOUNT_WALLET = "subaccount/UPDATE_SUB_ACCOUNT_WALLET";
