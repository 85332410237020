import style from "./index.module.scss";
import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "@/styles/cabinet.scss";
import { Alert } from "@/components/Alert";
import { getSubAccountStratum$, setShowModal } from "@/store/sub_accounts";
import { RootState, useAppDispatch } from "@/store";
import { useSubAccount } from "@/hooks";
import { useSelector } from "react-redux";
import { getCurrencies$ } from "@/store/references";
import { AddSubAccountDialog, CabinetStatrumUrlsComponent } from "@/features";
import { USER_INFO } from "@/variables";
import { verifySend } from "@/store/auth/api";

export function CabinetLayout() {
  const navigate = useNavigate();
  const [alert, setAlert] = useState(false);
  const dispatch = useAppDispatch();
  const { currentSubAccount } = useSubAccount();
  const showModal = useSelector(
    (state: RootState) => state.subAccount.showModal,
  );
  const currencies = useSelector(
    (state: RootState) => state.references.currencies,
  );
  const checkVerification = () => {
    const token = JSON.parse(
      localStorage.getItem(USER_INFO) || JSON.stringify({}),
    );
    if (token) {
      if (!token.user?.is_email_verified) {
        verifySend(token.user?.email).then();
        navigate("/verify");
      }
    }
  };
  useEffect(() => {
    if (currentSubAccount) {
      dispatch(getSubAccountStratum$(currentSubAccount)).unwrap();
    }
    if (!currencies?.length) {
      dispatch(getCurrencies$());
    }

    checkVerification();
  }, [currentSubAccount]);
  return (
    <section className={style.cabinetLayout}>
      {showModal && (
        <AddSubAccountDialog onClose={() => dispatch(setShowModal(false))} />
      )}
      <div>
        {alert && (
          <Alert
            text={"Скопировано"}
            onClose={() => {
              setAlert(false);
            }}
          />
        )}
        <div className={style.cabinetLayout__content}>
          <Outlet />
        </div>
      </div>
      <CabinetStatrumUrlsComponent />
    </section>
  );
}
