import style from "./index.module.scss";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "@/store";
import { useSubAccount } from "@/hooks";
import {
  getSubAccountEarnings$,
  getSubAccountSummary$,
  setLoading,
} from "@/store/sub_accounts";
import { useSelector } from "react-redux";
import {
  ButtonGroup,
  Dropdown,
  DropdownButton,
  Spinner,
} from "react-bootstrap";
import { useTranslations } from "use-intl";
import { StatisticDetails } from "@/features";
import { CabinetCard } from "@/components/Card";
import { HomeCards } from "@/features/ui/home-cards";

export default function Statistic() {
  const dispatch = useAppDispatch();
  const { currentSubAccount, loading } = useSubAccount();
  const summary = useSelector(
    (state: RootState) => state.subAccount.subAccountSummary,
  );
  const earnings = useSelector((state: RootState) => state.subAccount.earnings);
  useEffect(() => {
    let promise: any;
    let earningPromise: any;
    if (currentSubAccount) {
      dispatch(setLoading(true));
      promise = dispatch(getSubAccountSummary$(currentSubAccount));
      earningPromise = dispatch(
        getSubAccountEarnings$({ sub_account_name: currentSubAccount }),
      );
    }
    return () => {
      dispatch(setLoading(false));
      if (promise) {
        promise.abort();
      }
      if (earningPromise) {
        earningPromise.abort();
      }
    };
  }, [currentSubAccount]);
  return (
    <div className={style.home}>
      <div className={style.home__blocks}>
        {loading ? (
          <Spinner animation="grow" />
        ) : summary?.length ? (
          <>
            <HomeCards earnings={earnings} summary={summary} />
          </>
        ) : (
          ""
        )}
      </div>
      <CabinetCard className={"mb-[20px]"}>
        <StatisticDetails />
      </CabinetCard>
    </div>
  );
}
