import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { verifyWalletToken } from "@/store/auth/api";
import { toast } from "react-toastify";

export default function VerifyWallet() {
  const navigate = useNavigate();
  const { token } = useParams();
  useEffect(() => {
    if (!token) {
      toast.error("No token provided");
      navigate("/cabinet");
      return;
    }
    verifyWalletToken(token)
      .then(() => {
        toast.success("Token confirmed");
        navigate("/cabinet");
      })
      .catch((e) => {
        toast.error(e.response?.data?.message || "Wrong token");
        navigate("/cabinet");
      });
  }, []);
  return (
    <div className={"section-padding"}>
      <div className={"container"}>
        <div className={"col-lg-6 mx-auto text-center"}>
          <Spinner animation={"grow"} />
        </div>
      </div>
    </div>
  );
}
