import axiosClient from "@/libs/axiosClient";
import { customRequest } from "@/utils/custom-fetch";

enum API_URL {
  all = "/api/v1/pools/sub_account/api_key/all/",
  create = "/api/v1/pools/sub_account/api_key/create/",
  delete = "/api/v1/pools/sub_account/api_key/delete/",
  ENABLED_CODE = "/api/v1/otp/is_enabled/",
  CODE = "/api/v1/otp/verify_code/",
  CODE_ENABLE = "/api/v1/otp/enable/",
  GENERATE = "/api/v1/otp/qr_code/",
  EMAILS = "/api/v1/users/trusted_emails/",
  NOTIFICATIONS = "/api/v1/telegram_notification/token/",
}
export const getAllApiKey = (name: string) => {
  return customRequest.getItem(API_URL.all + `?sub_account_name=${name}`);
};
export const getTrustedEmails = () => {
  return customRequest.getItem(API_URL.EMAILS);
};
export const getNotifications = () => {
  return customRequest.getItem(API_URL.NOTIFICATIONS).then((res) => res.data);
};
export const addApiKey = (name: string) => {
  return customRequest.post(API_URL.create, { sub_account_name: name });
};

export const deleteApiKey = (body: {
  api_key: string;
  sub_account_name: string;
}) => {
  return customRequest.delete(API_URL.delete, body);
};

export const checkCode = (code: string) => {
  return customRequest
    .getItem(API_URL.CODE + `?code=${code}`)
    .then((res) => res.data);
};

export const generateQrCode = () => {
  return customRequest.getItem(API_URL.GENERATE).then((res) => res.data);
};

export const qrCodeEnable = (code: string) => {
  return customRequest.post(API_URL.CODE_ENABLE, { code });
};

export const isQrCodeEnabled = () => {
  return customRequest.getItem(API_URL.ENABLED_CODE).then((res) => res.data);
};
