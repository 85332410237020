import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import { DateRange } from "react-day-picker";
import { format, subDays } from "date-fns";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { cn } from "@/lib/utils";
import { toast } from "react-toastify";
import { useTranslations } from "use-intl";

export function PickDate({
  defaultDate,
  onChange,
}: {
  defaultDate?: { from: Date; to: Date };
  onChange: (val: { from: Date; to: Date }) => void;
}) {
  const i18n = useTranslations("cabinet.history");
  const [date, setDate] = useState<DateRange | undefined>(
    defaultDate || {
      from: subDays(new Date(), 30),
      to: new Date(),
    },
  );
  const [open, setOpen] = useState(false);
  return (
    <Popover open={open}>
      <PopoverTrigger asChild>
        <Button
          id="date"
          onClick={() => {
            setOpen((prev) => !prev);
          }}
          variant={"outline"}
          className={cn(
            "justify-start text-left text-third bg-white font-normal flex border-[1px] border-third gap-4 mr-4",
            !date && "text-muted-foreground",
          )}
        >
          <CalendarIcon className={"text-third"} />
          {date?.from ? (
            date.to ? (
              <>
                {format(date.from, "LLL dd, y")} -{" "}
                {format(date.to, "LLL dd, y")}
              </>
            ) : (
              format(date.from, "LLL dd, y")
            )
          ) : (
            <span className={"text-third"}>{i18n("dateTitle")}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-auto p-0 z-[116] bg-white text-center"
        align="start"
      >
        <Calendar
          initialFocus
          mode="range"
          defaultMonth={date?.from}
          selected={date}
          onSelect={(date) => {
            setDate(date);
          }}
          numberOfMonths={2}
        />
        <Button
          className={"mb-2"}
          onClick={() => {
            setOpen(false);
            if (date) {
              if (!date.from || !date.to) {
                toast.error(i18n("invalid"));
                return;
              }
              onChange({
                from: date.from || subDays(new Date(), 30),
                to: date.to || new Date(),
              });
            }
          }}
        >
          {i18n("apply")}
        </Button>
      </PopoverContent>
    </Popover>
  );
}
